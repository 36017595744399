<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">押金申退查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item>
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.dateType',
                  {
                    initialValue: '0',
                  },
                ]"
              >
                <a-select-option value="0">申退日期</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                size="small"
                v-decorator="['searchForm.rangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="退款审批状态">
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.refundStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option
                v-for="(status) in refundDepositStatus"
                :key="status.value"
                :value="status.value">{{ status.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="退款类型">
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.refundUse',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option
                v-for="(status) in refundDepositUseStatus"
                :key="status.value"
                :value="status.value">{{ status.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-input
                size="small"
                placeholder="押金申退单号"
                v-decorator="[
                  'searchForm.refundNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="机票订单号">
              <a-input
                size="small"
                placeholder="机票订单号"
                v-decorator="[
                  'searchForm.orderNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
              <!-- <a-button size="small"> 导出 </a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{x: 'max-content'}"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            :loading="loading"
            @change="onTableChange"
          >
            <template slot="index" slot-scope="index">
              {{ index  }}
            </template>
            <template slot="refundNo" slot-scope="refundNo, row">
              <router-link
                :to="{
                  path: '/orderManager/depositRefundDetail',
                  query: { id: row.id },
                }"
                >{{ refundNo }}</router-link
              >
            </template>
            <template slot="segments" slot-scope="segments">
              <div v-for="(item, idx) in segments" :key="idx">
                {{ item.orgCity | airCity }}-{{ item.dstCity | airCity }}
              </div>
            </template>
            <template slot="payType" slot-scope="payType">
              {{ payType | bank }}
            </template>
            <template slot="refundStatus" slot-scope="refundStatus">
              {{ refundStatus | refundDepositStatusFilter }}
            </template>
            <template slot="refundUse" slot-scope="refundUse">
              {{ refundUse | refundDepositUseStatusFilter }}
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'
// import { validate } from '@/utils/validate'

import { refundDepositStatus, refundDepositUseStatus } from '@/dicts/order'
import { bank } from '@/filters/pay'
import { refundDepositStatus as refundDepositStatusFilter, refundDepositUseStatus as refundDepositUseStatusFilter } from '@/filters/order'

import { fetchDepositRefundList } from '@/api/order'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '押金申退号',
    dataIndex: 'refundNo',
    scopedSlots: { customRender: 'refundNo' }
  },
  {
    title: '航线',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    scopedSlots: { customRender: 'payType' }
  },
  {
    title: '支付流水',
    dataIndex: 'paymentNo'
  },
  {
    title: '支付金额',
    dataIndex: 'payAmount'
  },
  {
    title: '手续费',
    dataIndex: 'refundFee'
  },
  {
    title: '审核退款金额',
    dataIndex: 'actualRefundAmount'
  },
  {
    title: '申退日期',
    dataIndex: 'createTime'
  },
  {
    title: '退款类型',
    dataIndex: 'refundUse',
    scopedSlots: { customRender: 'refundUse' }
  },
  {
    title: '退款审批状态',
    dataIndex: 'refundStatus',
    scopedSlots: { customRender: 'refundStatus' }
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    const segments = col.segmentInfos.map((seg) => {
      const { depCode, arrCode } = seg
      return { orgCity: depCode, dstCity: arrCode }
    })

    return {
      key: col.id,
      index: page * size + (index + 1),
      ...col,
      ...{
        segments: segments
      }
    }
  })
}

export default {
  name: 'depositRefundSearch',
  components: {},
  mixins: [tableBaseMixin, orderSearchMixin],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      columns,
      refundDepositStatus,
      refundDepositUseStatus,
      params: {
        depCode: '',
        arrCode: '',
        payTimeEnd: '',
        payTimeStart: '',
        orderNo: '',
        refundNo: '',
        createTimeEnd: '',
        createTimeStart: '',
        refundPaymentTimeStart: '',
        refundPaymentTimeEnd: '',
        paymentNo: '',
        refundStatus: '',
        refundUse: '',
        page: 0,
        size: 10
      }
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await fetchDepositRefundList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm

      const params = {
        orderNo: fields.orderNo,
        refundNo: fields.refundNo,
        refundStatus: fields.refundStatus,
        refundUse: fields.refundUse
      }

      const dateTypeTimeMap = {
        0: {},
        1: {}
      }

      if (fields.rangeTime) {
        dateTypeTimeMap[fields.dateType] = {
          start: fields.rangeTime[0] ? fields.rangeTime[0].format('YYYY-MM-DD 00:00:00') : '',
          end: fields.rangeTime[1] ? fields.rangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
        }

        params.createTimeStart = dateTypeTimeMap[0].start
        params.createTimeEnd = dateTypeTimeMap[0].end

        params.refundPaymentTimeStart = dateTypeTimeMap[1].start
        params.refundPaymentTimeEnd = dateTypeTimeMap[1].end
      }

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    handleFieldValidate (rule, value, callback) {
      // validate(
      //   'search-order',
      //   this.bookForm.getFieldsValue(),
      //   rule.fullField
      // ).then(({ valid, error }) => {
      //   if (valid) {
      //     callback()
      //   } else {
      //     callback(error)
      //   }
      // })
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.searchForm.resetFields()
    }
  },
  filters: {
    refundDepositStatusFilter,
    refundDepositUseStatusFilter,
    bank
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
